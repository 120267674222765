@use '../../styles/yr' as *;

.warning__button {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: size(1) size(2);
  width: 100%;
}

.warning__icon {
  float: left;
  padding-left: 2px;
  padding-top: 2px;
}

.warning__toggle {
  height: 1.5rem;
  position: absolute;
  right: 1rem;
  text-align: right;
  top: 1.2rem;
  width: 1.5rem;

  .f-no-touch &:hover,
  .no-js &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    fill: inherit;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

.warning {
  margin-bottom: size(1);
  overflow: hidden;
  position: relative;
  border: 2px solid transparent;
  border-radius: rem(8px);
  color: var(--color-text-base);
}

.warning[data-severity='Moderate'] {
  background-color: var(--color-warnings-yellow-rest);

  &[data-expanded='true'] {
    transition: all 100ms ease-out;
    border-color: var(--color-warnings-yellow-border);
  }

  &[data-expanded='false'] {
    &:hover {
      transition: all 100ms ease-out;
      border-color: var(--color-warnings-yellow-border);
    }
  }
}

.warning[data-severity='Severe'] {
  background-color: var(--color-warnings-orange-rest);

  &[data-expanded='true'] {
    transition: all 100ms ease-out;
    border-color: var(--color-warnings-orange-border);
  }

  &[data-expanded='false'] {
    &:hover {
      transition: all 100ms ease-out;
      border-color: var(--color-warnings-orange-border);
    }
  }
}

.warning[data-severity='Extreme'] {
  background-color: var(--color-warnings-red-rest);

  &[data-expanded='true'] {
    transition: all 100ms ease-out;
    border-color: var(--color-warnings-red-border);
  }

  &[data-expanded='false'] {
    &:hover {
      transition: all 100ms ease-out;
      border-color: var(--color-warnings-red-border);
    }
  }
}

.warning__content {
  padding: 0 size(2) size(1) size(2);
}

/**
 *  1. Browsers that don't have JS or don't pass the cut the mustard test
 *     need to always have warnings expanded.
 */
.no-js .warning__body[hidden] {
  display: flex;
}

.warning__content-heading {
  display: block;
  margin-top: size(2);
}

.warning__content-list {
  list-style: disc;
  padding-left: size(2);
}

.warning__severity {
  line-height: 1.5em;
  margin: 1rem 0 0 0;
}
.warning__link {
  color: var(--color-text-link);
}

.warning__image-column {
  @include mq-lte($mq-640) {
    margin-top: 1rem;
  }

  @include mq-gt($mq-640) {
    max-width: 30rem;
  }
}

.warning__image-button {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.warning__image {
  width: 100%;
}
