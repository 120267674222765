@use '../../styles/yr' as *;

.tide-card__table-container {
  margin-bottom: size(2);
}

.tide-card-table {
  width: 100%;
}

.tide-card__table-cell {
  width: 40%;
}

.tide-card__table-header--tide-value,
.tide-card__table-cell--tide-value {
  text-align: right;

  @include mq-gt($mq-675) {
    padding-right: size(8);
  }
}

.tide-card__table-header {
  width: 50%;
}

.tide-card__table-row {
  height: size(4);
  border-bottom: $divider-solid;
}

.tide-card__table-icon {
  color: var(--color-fill-accent-core-blue);
  margin-right: size(1);
}

.tide-card__table-tide {
  display: flex;
}

.tide-card__empty-state {
  margin-top: size(4);
}

.tide-card__empty-header {
  margin-top: size(2);
}
