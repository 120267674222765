@use '../../styles/yr' as *;

.card {
  background-color: var(--color-background-elevated);
  border-radius: size(1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: $theme-box-shadow;

  &[data-hover-shadow='true'] {
    @include hover($focus-within: true) {
      box-shadow: $theme-box-shadow-elevated;
    }
  }
}

.card__header {
  display: grid;
  grid-template-areas:
    'title infobutton'
    '. .'
    'subtitle subtitle';
  grid-template-rows: size(3) size(0.5) auto;
  grid-template-columns: 1fr auto;
  margin-bottom: size(2);
}

.card__header-title {
  grid-area: title;
}

.card__header-subtitle {
  grid-area: subtitle;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card__header-info-button {
  grid-area: infobutton;
  align-self: center;
}

.card__content--with-margin {
  margin: size(2);
}

.card__link-footer {
  position: relative;
  padding: rem(12px) size(2);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  .card--empty & {
    height: size(5);
  }
}

.card__link-footer-link {
  display: inline-flex;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.card__link-footer-icon {
  margin-left: size(0.5);
  position: relative;
  top: -1px; // Adjust icon so it aligns better with the text
}
